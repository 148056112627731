<template>
  <div class="contact">
<!--    <div v-if="!cardOpen" class="open" @click="cardOpen = true">-->
<!--      <div  v-for="c in contacts" :key="c.name">-->
<!--        <div class="contact-icon-mini">-->
<!--          <font-awesome-icon class="center" :icon="['fa',c.icon]"></font-awesome-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="block-content">
<!--      <div v-if="!isMobile()" class="close" @click="cardOpen = false">-->
<!--        <font-awesome-icon icon="times"></font-awesome-icon>-->
<!--      </div>-->
      <div class="contact-group" v-for="c in contacts" :key="c.name">
        <div class="contact-icon-wrap">
          <font-awesome-icon class="center" :icon="['fa',c.icon]"></font-awesome-icon>
        </div>
        <div v-if="c.name === 'telephone'" class="contact-text">
          <a style="font-size: 14pt" :href="'tel:'+c.text">{{ c.text }}</a>
        </div>
        <div v-else-if="c.name === 'email'" class="contact-text">
          <a style="font-size: 14pt" :href="'email:'+c.text">{{ c.text }}</a>
        </div>
        <div v-else class="contact-text">
          {{ c.text }}
        </div>
      </div>
    </div>
    <div class="map">
      <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=215145674883" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
name: "Contact",
  components: {},
  mounted() {
  },
  data: () => ({
    cardOpen: true,
    contacts: [
      {name: 'address', icon: 'home', text: 'Карачаево-Черкесская Республика, Посёлок Архыз, Поляна Таулу' },
      {name: 'telephone', icon: 'phone', text: '+7 (800) 222-61-74' },
      {name: 'email', icon: 'envelope', text: 'svarkhyz@bk.ru' },
      {
        name: 'location',
        icon: 'location-arrow',
        text: '🚗 Как к нам доехать️\n\n' +
            'Проезжаете посёлок Архыз, едете в сторону Романтика. ' +
            'Через 5 км поворот налево на мост. Едете прямо через лес. Дорога одна. ' +
            'Доезжаете до моста, переезжаете через реку и едете по дороге в горку. ' +
            'Справа проезжаете мемориал. ' +
            'Дорогу держите всегда правее и на втором повороте поворачиваете на право. ' +
            'По пути будут указатели.\n' +
            'Доброго пути! 🏡💛'
      }
    ]
  }),
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  }
}
</script>

<style scoped>
.contact{
  display: flex;
  flex-direction: row;
}
.block-content{
  background-color: #fff;
  width: 30%;
  padding: 1rem;
  flex-shrink: 0;
}
.map{
  flex-grow: 1;
  padding: 1rem;
  background-color: #fff;
}
.open{
  background-color: #fff;
  width: 10rem;
  height: 3rem;
  padding: .3rem;
  position: absolute;
  bottom: 3rem;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.close{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.contact-icon-mini{
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 12pt;
  background-color: #ffc629;
  border-radius: 50%;
  margin: 0 .1rem;
}
.contact-icon-wrap{
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 12pt;
  margin: .5rem auto .5rem auto;
  background-color: #ffc629;
  border-radius: 50%;
}
.center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #484848;
}
.contact-text {
  font-size: 11pt;
  padding: 0 2rem;
  text-align: center;
  white-space: break-spaces;
}
@media (max-width: 1140px) {
  .contact{
    flex-direction: column;
  }
  .block-content{
    width: 100%;
  }
  .map{
    height: 25rem;
  }
}
@media (max-width: 480px) {
  .block-content{
    position: relative;
    width: 100%;
  }
  .contact-icon-wrap {
    width: 3rem;
    height: 3rem;
    font-size: 20pt;
  }
  .map{
    position: relative;
    height: 30em;
  }
}

</style>
