<template>
  <div class="widget">
    <div id="block-search">
      <div id="tl-search-form" class="tl-container">
        <noindex><a href="https://www.travelline.ru/products/tl-hotel/" rel="nofollow" target="_blank">TravelLine</a></noindex>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BookingWidget",
  mounted() {
    function init(w) {
      const q = [
        ["setContext", "TL-INT-svarkhyz-ru_2024-12-24", "ru"],
        ["embed", "search-form", {
          container: "tl-search-form"
        }]
      ];
      const h = ["ru-ibe.tlintegration.ru", "ibe.tlintegration.ru", "ibe.tlintegration.com"];
      const t = w.travelline = (w.travelline || {}),
          ti = t.integration = (t.integration || {});
      ti.__cq = ti.__cq? ti.__cq.concat(q) : q;
      if (!ti.__loader) {
        ti.__loader = true;
        const d=w.document,c=d.getElementsByTagName("head")[0]||d.getElementsByTagName("body")[0];
        const e = (s,f) => {return function() {w.TL||(c.removeChild(s),f())}}
        (function l(h) {
          if (0===h.length) return; var s=d.createElement("script");
          s.type="text/javascript";s.async=!0;s.src="https://"+h[0]+"/integration/loader.js";
          s.onerror=s.onload=e(s,function(){l(h.slice(1,h.length))});c.appendChild(s)
        })(h);
      }
    }

    init(window);
  }
}
</script>

<style>
.widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#block-search,
#block-search * {
  box-sizing: border-box;
  min-width: max(44vw, 350px);
}

#block-search {
  background: #484848;
  border-top: 2px solid #ffc629;
}

.tl-container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 30px;
}
</style>